<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card 
                    flat
                    >
                        <v-card-text>
                            <div class="form-box-title">{{ itemsTitle }}</div>
                            <template v-if="flatItems.length === 0">
                                {{ $t('Нет_доступных_элементов') }}
                            </template>
                            <template
                                v-else
                            >
                                <v-list class="modal-select-list" dense outlined>
                                    <v-list-item-group
                                        v-model="selectedItem"
                                        color="cyan darken-1"
                                    >                                        
                                        <v-list-item
                                            v-for="(item, i) in flatItems"
                                            :key="i"
                                            :value="item"
                                            @dblclick="doubleSelect(item)"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.title"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                    color="cyan" 
                    text 
                    depressed 
                    @click="save" 
                    v-if="selectedItem">
                        {{ $t("Создать") }}
                    </v-btn>

                    <v-btn 
                    color="blue-grey" 
                    text
                    depressed
                    @click="cancel">
                        {{ $t("Отмена") }}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import i18n from '@/i18n'
import { mapGetters } from 'vuex';

export default {
    name: "CreateDocumentDialog",
    data () {
        return {
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            selectedItem: null,
        }
    },
    computed: {
        ...mapGetters('auth', ['getUserInfo']),
        title() {
            return this.$t("Создание");
        },
        itemsTitle() {
            return this.$t("Выберите_тип");
        },
        flatItems() {
            let items = [];
            
            switch (this.$route.name)
            {
                case 'Enterprises':
                {
                    items.push({ title: this.$t("Организация"), params: { manually: true, createType: "Enterprise" } });
                    items.push({ title: this.$t("Контрагент (Юридическое лицо)"), params: { manually: true, createType: "ContractorEnterprise" } });
                    items.push({ title: this.$t("Контрагент (Физическое лицо)"), params: { manually: true, createType: "ContractorPerson" } });
                    break;
                }
                case 'Users':
                {
                    items.push({ title: this.$t("Внутрений пользователь"), params: { manually: true, createType: "InnerEmployee" } });
                    break;
                }
                case 'Referenses':
                {
                    items.push({ title: this.$t("Должность"), params: { manually: true, createType: "JobTitle" } });
                    items.push({ title: this.$t("Роль"), params: { manually: true, createType: "Role" } });
                    if (process.env.VUE_APP_CONFIGURATION != 'C5')
                        items.push({ title: this.$t("Шаблон отчета"), params: { manually: true, createType: "ReportQueryTemplate" } });
                    break;
                }
                default:
                {

                    break;
                }
            }

            return items;
        }
    },
    methods: {
        async open(options = {})
        {
            this.selectedItem = null;
            this.visible = true;
            this.options = Object.assign(this.options, options);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие отменено"
            });
        },
        save() {
            this.visible = false;
            this.resolve(this.selectedItem);
        },
        doubleSelect(item) {
            this.visible = false;
            this.resolve(item);
        }
    }
}
</script>