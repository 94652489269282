<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                
                <v-card-text>
                    <v-form class="wrapperFormModal" ref="form" lazy-validation>
                        <v-card flat>
                            <v-card-text>
                                <div class="form-box-title">{{ $t("Персональные_сведения") }}</div>

                                <!-- поле Фамилия -->
                                <v-row no-gutters>

                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">
                                            {{ $t("Фамилия") }} 
                                        </label>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="8">
                                        <v-text-field
                                            v-model="employee.first_name"
                                            hide-details
                                            outlined
                                            dense
                                            :rules="requiredRule"
                                        >
                                        </v-text-field>

                                    </v-col>

                                </v-row>

                                <!-- поле Имя -->
                                <v-row no-gutters>

                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">
                                            {{ $t("Имя") }} 
                                        </label>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="8">
                                        <v-popover
                                            trigger="click focus"
                                            placement="right"
                                            class="popover-field"
                                            @show="searchPersons"
                                        >
                                            <v-text-field
                                                v-model="employee.last_name"
                                                hide-details
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                                @focus="searchPersons"
                                            >
                                            </v-text-field>

                                            <template v-if="findedPerson.length>0" slot="popover">
                                                <ul class="ribbon-button-submenu">
                                                    <li 
                                                        @click.stop="onExistedPersonSelect"
                                                        v-close-popover
                                                    >
                                                        Показать
                                                    </li>
                                                </ul>
                                                <span class="ribbon-button-submenu-title">Найдено {{findedPerson.length}} подходящих персон</span>
                                            </template>
                                            <template v-else-if="employee.first_name"  slot="popover">
                                                <span class="ribbon-button-submenu-title">Нет подходящих персон</span>
                                            </template>

                                        </v-popover>                                                
                                    </v-col>

                                </v-row>

                                <!-- поле Отчество -->
                                <v-row no-gutters>

                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">
                                            {{ $t("Отчество") }} 
                                        </label>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="8">
                                        <v-text-field
                                            v-model="employee.middle_name"
                                            hide-details
                                            outlined
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>

                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{ $t("Дата_рождения") }}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <v-menu 
                                            v-model="BirthDateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="BirthDate | formattedDate"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on" 
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    class="datepick-input"
                                                    clearable
                                                >
                                                </v-text-field>
                                            </template>

                                            <v-date-picker 
                                                v-model="BirthDate"
                                                @input="BirthDateMenu = false"
                                                color="teal"
                                                :first-day-of-week="1"
                                            >
                                            </v-date-picker>
                                        </v-menu>

                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>  
                                            
                        <v-card flat>
                            <v-card-text>
                                <div class="form-box-title">{{ $t("Безопасность") }}</div>
                                <v-row class="full-width-row" no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{ $t("Логин") }}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <v-text-field
                                            v-model="employee.user.login"
                                            hide-details
                                            outlined
                                            dense
                                            :rules="requiredRule"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="full-width-row" no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{ $t("Уровень_доступа") }}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <v-autocomplete
                                                v-model="employee.user.level_access"
                                                :items="allowSetLevels"
                                                item-text="Value"
                                                item-value="id"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :rules="getIntegerNotNullRule"
                                            >
                                            </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-form>    
                </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        v-if="employee.first_name && employee.last_name && employee.user.login && employee.user.level_access>0"
                        color="cyan" 
                        text 
                        depressed 
                        @click="save" 
                        >
                        {{$t("Сохранить")}}
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n';
import { mapGetters, mapActions } from 'vuex';
import { httpAPI } from "@/api/httpAPI";
import sys from '@/services/system';

export default {
    name: "CreateInnerEmployeeDlg",
    data () {
        return {
            title: i18n.t("Создание_внутреннего_сотрудника"),
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            employee: {
                emterprise_id:'',
                person_id:'',
                first_name:'',
                last_name:'',
                middle_name:'',
                birth_date:null,
                user:{
                    login:'',
                    password:'',
                    level_access:0
                }
            },
            BirthDateMenu: false,
            findedPerson:[],
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed:{
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            iinRule: 'getIinRule',
            getIntegerNotNullRule: 'getIntegerNotNullRule',
        }),
        ...mapGetters('auth', ['getUserInfo']),
        ...mapGetters('references', ['GetSecurityLevelAccess']),
        BirthDate: {
            get: function() {
                return this.employee.birth_date ? this.$moment(this.employee.birth_date).format('YYYY-MM-DD') : null;
            },
            set: function(newValue) {
                this.employee.birth_date = newValue ? `/Date(${Date.parse(newValue)})/`: newValue;
            }
        },
        allowSetLevels() {
            return this.GetSecurityLevelAccess.filter(item=>item.id>0 && item.id<100)
        }
    },
    methods: {
        ...mapActions('references', ['getLicenseEnterprise']),
        async open()
        {
            let licent = await this.getLicenseEnterprise();
            this.employee = {
                enterprise_id: licent.id,
                person_id: '00000000-0000-0000-0000-000000000000',
                person_code: '',
                first_name: '',
                last_name: '',
                middle_name: '',
                birth_date: null,
                user:{
                    login: '',
                    password: '',
                    level_access: 0
                }
            },
            this.visible = true;
            //this.$refs?.form.validate();
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        save() {
            this.visible = false;

            let validateResult = this.$refs.form.validate();
            if (!validateResult) {
                this.$notify.alert(this.$t("Не_заполнены_обязательные_поля"));
                return;
            }

            this.resolve(this.employee);
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие отменено"
            });
        },
        //#region выбор персоны
        async searchPersons() {
            if (this.employee.first_name){
                let response = await httpAPI({
                    url: `/api/ems/searchpersonwithcheck`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({ firstName:this.employee.first_name}) },
                });
                this.findedPerson = response?.data?.payload ?? [];
            }
            else 
                this.findedPerson = [];
        },
        async onExistedPersonSelect() {
            let selectMemberParams =  {
                title: this.$t("Выбор_персоны"),
                fromSource: true,
                source: this.findedPerson.map( x =>
                    [ 
                        x.person_id, //по идее, тут должен быть workplace, но можно использовать и person_id в качестве идентификатора
                        `${x.first_name} ${x.last_name} ${x.middle_name}`, //формируем для отображение полное имя, формат Фамилия И.О. тут не подходит
                        null, //employeeId нас не интересует
                        `${x.person_code ? `${this.$t("ИИН")}: ${x.person_code}` : ""}${x.birth_date ? ` ${this.$t("Дата_рождения")}: ${this.$moment(x.birth_date).format('DD.MM.YYYY')}` : ""}`//подробности для персоны, ИИН и дата рождения, т.к. поля присутствуют на форме карточки
                    ]),
                multiple: false,
                selected: []
            }

            try
            {
                let selectedPerson = await this.$store.dispatch('dialogs/selectMembers/open', selectMemberParams);
                this.selectPerson(this.findedPerson.find( x => x.person_id === selectedPerson.workplaceId));
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async selectPerson(person) {
            if (person){
                if (person.check){
                    //let checkinfo = JSON.parse(person.check);
                    //this.$notify.alert(this.$t('Персона_уже_работает_в') +' '+ checkinfo.entname);
                    this.$notify.alert(person.check);
                    return;
                }
                else {
                    this.employee.person_id = person.person_id;
                    this.employee.first_name = person.first_name;
                    this.employee.last_name = person.last_name;
                    this.employee.middle_name = person.middle_name;
                    this.employee.birth_date = person.birth_date;
                    this.findedPerson = [];
                }
            }
        },
        //#endregion
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    },
    updated: function () {
        this.validateFormMethod();
    }
}
</script>