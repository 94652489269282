<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{$t("Сгенерированный пин-код")}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Пин-код") }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div class="onlyReadData">{{ password }}</div>
                        </v-col>
                    </v-row>

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Закрыть")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "ShowNewPassDlg",
    data () {
        return {
            visible: false,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            password: ''
        }
    },
    methods: {
        async open(pass, options = {})
        {
            this.reset();
            this.visible = true;
            this.options = Object.assign(this.options, options);
            this.password = pass;
        },
        cancel() {
            this.visible = false;
        },
        reset() {
            this.password = '';
        },

    }
}
</script>